@font-face {
  font-family: 'Manrope';    //This is what we are going to call
  src: url('./assets/fonts/Manrope-Regular.ttf');
}

@font-face {
  font-family: 'Manrope-Medium';    //This is what we are going to call
  src: url('./assets/fonts/Manrope-Medium.ttf');
}

@font-face {
  font-family: 'Manrope-Bold';    //This is what we are going to call
  src: url('./assets/fonts/Manrope-Bold.ttf');
}

* {
  font-family: Manrope, serif !important;
}

ion-content {
  font-family: Manrope, serif !important;
}

// NORMAL TEXT
.normalText1 {
  font-size: 8px;
  font-family: Manrope, serif !important;
}

.normalText2 {
  font-size: 10px;
  font-family: Manrope, serif !important;
}

.normalText3 {
  font-size: 12px;
  font-family: Manrope, serif !important;
}

.normalText4 {
  font-size: 14px;
  font-family: Manrope, serif !important;
}

.normalText5 {
  font-size: 16px;
  font-family: Manrope, serif !important;
}

.normalText6 {
  font-size: 18px;
  font-family: Manrope, serif !important;
}

.normalText7 {
  font-size: 20px;
  font-family: Manrope, serif !important;
}

.normalText8 {
  font-size: 22px;
  font-family: Manrope, serif !important;
}

.normalText9 {
  font-size: 24px;
  font-family: Manrope, serif !important;
}

// MEDIUM TEXT
.mediumText1 {
  font-size: 8px;
  font-family: Manrope-Medium, serif !important;
}

.mediumText2 {
  font-size: 10px;
  font-family: Manrope-Medium, serif !important;
}

.mediumText3 {
  font-size: 12px;
  font-family: Manrope-Medium, serif !important;
}

.mediumText4 {
  font-size: 14px;
  font-family: Manrope-Medium, serif !important;
}

.mediumText5 {
  font-size: 16px;
  font-family: Manrope-Medium, serif !important;
}

.mediumText6 {
  font-size: 18px;
  font-family: Manrope-Medium, serif !important;
}

.mediumText7 {
  font-size: 20px;
  font-family: Manrope-Medium, serif !important;
}

.mediumText8 {
  font-size: 22px;
  font-family: Manrope-Medium, serif !important;
}

.mediumText9 {
  font-size: 24px;
  font-family: Manrope-Medium, serif !important;
}

// BOLD TEXT
.boldText1 {
  font-size: 8px;
  font-family: Manrope-Bold, serif !important;
}

.boldText2 {
  font-size: 10px;
  font-family: Manrope-Bold, serif !important;
}

.boldText3 {
  font-size: 12px;
  font-family: Manrope-Bold, serif !important;
}

.boldText4 {
  font-size: 14px;
  font-family: Manrope-Bold, serif !important;
}

.boldText5 {
  font-size: 16px;
  font-family: Manrope-Bold, serif !important;
}

.boldText6 {
  font-size: 18px;
  font-family: Manrope-Bold, serif !important;
}

.boldText7 {
  font-size: 20px;
  font-family: Manrope-Bold, serif !important;
}
