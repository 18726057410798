@keyframes fadeIn {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.unauthorized-view {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: url('assets/background.jpeg');
  background-size: cover;
}

.page {
  padding: 16px 24px 16px 24px;
  width: 100%;
  max-width: 1200px;
}

.filter-page {
  padding: 0 24px 16px 24px;
  width: 100%;
  max-width: 1200px;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.width-300px {
  width: 300px;
}

.width-140px-simple {
  width: 140px;
}

.width-250px {
  width: 250px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.width-180px {
  width: 180px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.width-120px {
  width: 120px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.width-80px {
  width: 80px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.width-60px {
  width: 60px;
}

.width-40px {
  width: 40px;
}

.list-client-screen {
  overflow-y: auto;

  @media (max-width: 1372px) {
    max-height: calc(100vh - 900px);
  }

  @media (min-width: 1373px) {
    max-height: calc(100vh - 700px);
  }
}

.list-height-home {
  max-height: calc(100vh - 400px);
  overflow-y: auto;
}

.list-height {
  max-height: calc(100vh - 196px);
  overflow-y: auto;
}

.list-max-height-mobile {
  max-height: calc(100vh - 360px);
  overflow-y: auto;
}

.list-max-height-tablet {
  max-height: calc(100vh - 260px);
  overflow-y: auto;
}

.list-padding-horizontal {
  padding-left: 4px;
  padding-right: 4px;
}

.nav-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.flex-gap-10 {
  gap: 10px
}

.zIndex99999 {
  position: relative;
  z-index: 999999 !important;
}

.zIndexMinus1 {
  position: static;
  z-index: 0 !important;
}

.clickablePill {
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.popover-date-picker {
  --width: 300px;
}
