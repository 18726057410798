.card {
  width: 100%;
  border-radius: 16px;
  padding: 24px 16px 24px 16px;
  background: var(--ion-item-background);

  &.card:hover {
    cursor: pointer;
    transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-box-shadow: 0 0 24px 0 rgba(179,187,203,0.5);
    -moz-box-shadow: 0 0 24px 0 rgba(179,187,203,0.5);
    box-shadow: 0 0 24px 0 rgba(179,187,203,0.5);
  }
}

.navCard {
  width: 100%;
  border-radius: 32px;
  padding: 10px 10px 10px 10px;
  background: var(--ion-item-background);

  &.navCard:hover {
    cursor: pointer;
    transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-box-shadow: 0 0 24px 0 rgba(179,187,203,0.5);
    -moz-box-shadow: 0 0 24px 0 rgba(179,187,203,0.5);
    box-shadow: 0 0 24px 0 rgba(179,187,203,0.5);
  }
}

.list-items-itemOfList {
  .itemOfList:not(:last-child) {
    margin-bottom: 16px;
  }
  .itemOfListS:not(:last-child) {
    margin-bottom: 2px;
  }
}

.unClickableCard {
  cursor: default !important;
  transition: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}