*::-webkit-scrollbar-track {
  -webkit-box-shadow: var(--ion-background-color);
  border-radius: 10px;
  background-color: var(--ion-background-color);
}

*::-webkit-scrollbar {
  width: 6px;
  background-color: var(--ion-background-color);
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #555;
}

.mainBackground {
  background: var(--ion-background-color);
}

.itemBackground {
  background: var(--ion-item-background);
}

.border-success {
  border: 1px solid #0e8a40;
}

.border-error {
  border: 1px solid var(--ion-color-danger);
}

.g-search {
  border-radius: 24px;
  border: 1px solid var(--ion-color-medium);
  --border-radius: 30px;
  padding: 0;
}

ion-select {
  border-radius: 24px;
  background: var(--ion-color-primary);
  padding-right: 16px;
  height: 36px;
}

.text-danger {
  color: var(--ion-color-danger-shade);
}

.text-success {
  color: #0e8a40;
}

.text-shade {
  color: var(--ion-color-medium-shade);
}

.text-center {
  text-align: center;
}

.info-card {
  width: 100%;
  border-radius: 16px;
  padding: 24px 16px 24px 16px;
  background: var(--ion-item-background);
}

.g-select {
  padding: 12px 0 8px 0;
}

.g-static-select {
  height: 66px;
  padding-top: 20px;
}

.g-static-select-label {
  //padding: 10px 0 0 0;
  position: absolute;
  top: 4px;
  left: 0;
}

.g-select-border {
  border: 1px solid var(--ion-color-primary);
  background: var(--ion-item-background);
}

.border-radius-input {
  border-radius: 24px;
  --border-radius: 24px;
}

.g-select-size {
  min-height: 67px;
  width: 300px;
}

.g-select-clear {
  position: absolute;
  top: 20px;
  right: 6px;
  z-index: 100;
  cursor: pointer;
}

.g-multi-select-header {
  position: absolute;
  top: 0px;
  right: 2px;
  z-index: 100;
  cursor: pointer;
  width: 300px;
  padding: 10px 6px 10px 0;
}

.cursorPointer {
  cursor: pointer;
}

.g-toggle-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  width: 100%;
  padding: 16px 0 16px 0;
}

.g-text-area-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: column wrap;
  width: 100%;
  padding: 16px 0 16px 0;
}

.g-datetime-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column nowrap;
  width: 100%;
  padding: 6px 0 0 0;
}

.simple-select {
  background: transparent;
  width: 100%;
  justify-content: center;
}

.margin-t-minus-10 {
  margin-top: -6px !important;
}
